<template>
  <div @click="launchFilePicker">
    <slot />
    <input type="file" @change="fileSelected" ref="fileInput" />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Object, File, String],
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: "File",
    },
    helper: {
      type: String,
      default: null,
    },
    maxSize: {
      type: Number,
      default() {
        return 1024 * 2; // 2 MB
      },
    },
    pdfType: {
      type: Boolean,
      default: false,
    },
    imageType: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    acceptedType() {
      if (this.imageType || this.pdfType) {
        return this.imageType ? "image" : "pdf";
      }

      return null;
    },
  },
  methods: {
    launchFilePicker() {
      if (this.disabled) return;
      this.$refs.fileInput.click();
    },
    fileSelected(evt) {
      // const fieldName = evt.target.name;
      const file = evt.target.files;
      const { maxSize } = this;

      // check if user actually selected a file
      if (file.length > 0) {
        const uploadedFile = file[0];
        const size = uploadedFile.size / maxSize / maxSize;

        // Check file type
        if (this.acceptedType) {
          if (
            this.acceptedType === "image" &&
            !uploadedFile.type.match("image.*")
          ) {
            // check whether the upload is an image
            this.$emit("error", "El archivo no es una imagen");
            return;
          }
          if (
            this.acceptedType === "pdf" &&
            !uploadedFile.type.match("application/pdf")
          ) {
            // check whether the upload is an image
            this.$emit("error", "El archivo no es un pdf");
            return;
          }
        } else if (
          !uploadedFile.type.match("image.*") &&
          !uploadedFile.type.match("application/pdf")
        ) {
          // check whether the upload is an image
          this.$emit("error", "El archivo no es una imagen o un pdf");
          return;
        }

        // check whether the size is greater than the size limit
        if (size > 1) {
          const MAX_SIZE = (this.maxSize / 1024).toFixed(2);
          this.$emit(
            "error",
            `El archivo es muy grande. Tamaño maximo: ${MAX_SIZE}mb`
          );
        } else {
          this.$emit("update:modelValue", uploadedFile);
          this.$emit("onChange", uploadedFile);
        }
      }
    },
    removeFile() {
      this.$refs.fileInput.value = null;
    },
  },
};
</script>

<style scoped>
input {
  display: none;
}
</style>
