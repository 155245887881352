<template>
  <div class="review-score-selector">
    <div class="selector">
      <i
        :class="{
          'fa-regular fa-star': score < 1,
          'fa-solid fa-star': score >= 1,
        }"
        @click="score = 1"
      />
      <i
        :class="{
          'fa-regular fa-star': score < 2,
          'fa-solid fa-star': score >= 2,
        }"
        @click="score = 2"
      />
      <i
        :class="{
          'fa-regular fa-star': score < 3,
          'fa-solid fa-star': score >= 3,
        }"
        @click="score = 3"
      />
      <i
        :class="{
          'fa-regular fa-star': score < 4,
          'fa-solid fa-star': score >= 4,
        }"
        @click="score = 4"
      />
      <i
        :class="{
          'fa-regular fa-star': score < 5,
          'fa-solid fa-star': score >= 5,
        }"
        @click="score = 5"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    score: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
};
</script>
