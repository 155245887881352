<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-y="false">
      <div id="authContainer">
        <div class="responsive-container">
          <img :src="logo" alt="" />

          <div class="auth-card">
            <div class="auth-card-body">
              <h3>Login</h3>
              <ion-item lines="none">
                <ion-label position="stacked">Email</ion-label>
                <ion-input name="email" v-model="form.email" />
              </ion-item>
              <ion-item lines="none">
                <ion-label position="stacked">Password</ion-label>
                <ion-input
                  name="password"
                  type="password"
                  v-model="form.password"
                  @keyup.enter="onLogin"
                />
              </ion-item>

              <div class="pt-2 d-flex align-items-center">
                <ion-checkbox
                  mode="ios"
                  color="dark"
                  v-model="form.remember"
                ></ion-checkbox>
                <small>Recordarme</small>

                <router-link to="/forget_password" class="ml-auto text-white">
                  <small>Olvidaste la contraseña?</small>
                </router-link>
              </div>

              <ion-button mode="ios" expand="block" @click="onLogin">
                Login
              </ion-button>

              <p class="text-center">
                <small class="opacity-5 mt-3">
                  v{{ $store.getters.app_version }}
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
    <PWAPrompt />
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
  IonCheckbox,
} from "@ionic/vue";
import { mapGetters } from "vuex";
import PWAPrompt from "@/components/PWAPrompt";

export default {
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonItem,
    IonLabel,
    IonButton,
    IonCheckbox,
    PWAPrompt,
  },
  data: () => ({
    form: {
      user_id: null,
      email: null,
      password: null,
      remember: true,
    },
  }),
  computed: {
    ...mapGetters(["user"]),
    privacy_link() {
      return process.env.VUE_APP_PRIVACY_LINK;
    },
    logo() {
      return "/assets/logo.svg";
    },
  },
  async ionViewDidEnter() {
    if (this.$route.query.t && this.$route.query.uid) {
      this.$store.dispatch("loginAsUser", this.$route.query.uid).finally(() => {
        this.$store.dispatch("getUserData").then(() => {
          this.$router.push("/home");
        });
      });
    } else {
      await this.initApp();

      const default_email = await this.$storage.get("default_email");
      const default_pw = await this.$storage.get("default_pw");

      if (default_email) this.form.email = default_email;
      if (default_pw) this.form.password = default_pw;
    }
  },
  methods: {
    onLogin() {
      this.$store.dispatch("login", this.form).finally(() => {
        this.$store.dispatch("getUserData").then(() => {
          this.$router.push("/home");
        });
      });
    },
    async initApp() {
      await this.$store.dispatch("setCredentials");

      if (this.$store.getters.isLoggedIn) {
        this.$router.replace("/home");
        return;
      }
    },
  },
};
</script>
