String.prototype.ucwords = function () {
  const str = this.toLowerCase();
  return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function (s) {
    return s.toUpperCase();
  });
};

Array.prototype.sortBy = function (attr, dir = "asc") {
  return this.sort((a, b) =>
    (dir === "asc" ? a[attr] > b[attr] : a[attr] < b[attr]) ? 1 : -1
  );
};
