<template>
  <div :id="chartId" class="ct-chart" />
</template>

<script>
export default {
  props: {
    chartType: {
      type: String,
      default: "Line", // Line | Pie | Bar
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
    chartData: {
      type: Object,
      default: () => ({
        labels: [],
        series: [],
      }),
    },
  },
  data() {
    return {
      hoverCount: 0,
      imgHovered: false,
      fixedHeader: false,
      chartId: "no-id",
    };
  },
  mounted() {
    this.updateChartId();
    import("chartist").then((Chartist) => {
      const ChartistLib = Chartist.default || Chartist;
      this.$nextTick(() => {
        this.initChart(ChartistLib);
      });
    });
  },
  methods: {
    /** *
     * Assigns a random id to the chart
     */
    updateChartId() {
      const currentTime = new Date().getTime().toString();
      const randomInt = this.getRandomInt(0, currentTime);
      this.chartId = `div_${randomInt}`;
    },
    /** *
     * Initializes the chart by merging the chart options sent via
     * props and the default chart options
     */
    initChart(Chartist) {
      const chartIdQuery = `#${this.chartId}`;
      Chartist[this.chartType](chartIdQuery, this.chartData, this.chartOptions);
    },

    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>

<style></style>
