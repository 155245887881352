<template>
  <div class="pager">
    <ion-button
      mode="ios"
      color="dark-tint"
      size="small"
      @click="prevPage"
      :class="{
        disabled: !loop && pageData.current_page === 1,
      }"
    >
      <i class="fa fa-angle-left"></i>
    </ion-button>

    <small>
      <slot>
        Mostrando {{ pageData.from }} a {{ pageData.to }} de
        {{ pageData.total }} resultados
      </slot>
    </small>

    <ion-button
      mode="ios"
      color="dark-tint"
      size="small"
      @click="nextPage"
      :class="{
        disabled: !loop && pageData.current_page === pageData.last_page,
      }"
    >
      <i class="fa fa-angle-right"></i>
    </ion-button>
  </div>
</template>

<script>
import { IonButton } from "@ionic/vue";

export default {
  props: {
    pageData: {
      type: Object,
      default: () => ({
        from: 0,
        to: 0,
        total: 0,
        per_page: 0,
        current_page: 1,
        last_page: 1,
      }),
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    prevPage() {
      let nextPage = this.pageData.current_page - 1;

      console.log({ loop: this.loop, nextPage });

      if (nextPage === 0) {
        if (this.loop) {
          console.log("is loop");
          nextPage = this.pageData.last_page;
        } else {
          return;
        }
      }
      console.log({ nextPage });
      this.$emit("onChange", { page: nextPage });
    },
    nextPage() {
      let nextPage = this.pageData.current_page + 1;

      if (nextPage > this.pageData.last_page) {
        if (this.loop) {
          nextPage = 1;
        } else {
          return;
        }
      }
      this.$emit("onChange", { page: nextPage });
    },
  },
  emits: ["onChange"],
  components: {
    IonButton,
  },
};
</script>

<style scoped>
ion-button {
  --color: #fff;
}
</style>
