<template>
  <div class="pwa-prompt-wrapper" v-if="shown">
    <div
      class="pwa-prompt"
      :class="{
        'prompt-ios': isIos,
        'prompt-android': !isIos,
      }"
    >
      <div class="prompt-header">
        <div class="badge mr-2">
          <ion-icon :icon="cloudDownloadOutline" />
        </div>

        Instalar SMT App
      </div>
      <div class="prompt-body">
        <p>
          Instalá nuestra app en tu móvil para un rápido acceso en cualquier
          momento. Sin App Store y sin descarga, solo en 2 pasos.
        </p>

        <ol v-if="isIos">
          <li class="mb-2">
            Click en
            <div class="badge">
              <ion-icon :icon="shareOutline" />
            </div>
          </li>
          <li>
            Selecioná
            <div class="badge">Agregar a Inicio</div>
          </li>
        </ol>

        <button
          type="button"
          class="primary mb-2"
          @click="installPWA"
          v-if="!isIos"
        >
          Instalar
        </button>
        <button type="button" @click="dismissPrompt">No, gracias</button>
      </div>
    </div>
  </div>
</template>

<script>
import { IonIcon } from "@ionic/vue";
import { cloudDownloadOutline, shareOutline } from "ionicons/icons";

export default {
  components: {
    IonIcon,
  },
  data: () => ({
    shown: false,
    installEvent: null,
    showAgainAt: 0,
    appInstalled: false,
  }),
  setup: () => ({
    cloudDownloadOutline,
    shareOutline,
  }),

  computed: {
    userAgent() {
      return navigator.userAgent;
    },
    isIos() {
      return this.userAgent.includes("Mac");
    },
    swExists() {
      return "serviceWorker" in navigator;
    },
    isStandalone() {
      return (
        navigator.standalone ||
        window.matchMedia("(display-mode: standalone)").matches
      );
    },
    isFullScreen() {
      return window.matchMedia("(display-mode: fullscreen)").matches;
    },
    isMinimalUI() {
      return window.matchMedia("(display-mode: minimal-ui)").matches;
    },
    showAgain() {
      return Date.now() < this.showAgainAt;
    },
    canShow() {
      if (!this.swExists) return false;

      if (this.isStandalone || this.isFullScreen || this.isMinimalUI)
        return false;

      if (this.appInstalled) return false;

      if (this.showAgain) return false;

      return true;
    },
  },

  async beforeMount() {
    this.showAgainAt = (await this.$storage.get("show_prompt")) || 0;
    this.appInstalled = (await this.$storage.get("appInstalled")) || false;

    if (this.canShow) {
      if (!this.isIos) {
        window.addEventListener("beforeinstallprompt", (e) => {
          e.preventDefault();
          this.installEvent = e;
          this.shown = true;
        });
      } else {
        this.shown = true;
      }
    }
  },

  methods: {
    dismissPrompt() {
      this.shown = false;
      const oneDay = 1000 * 60 * 60 * 24;
      this.$storage.set("show_prompt", Date.now() + oneDay);
    },

    async installPWA() {
      this.installEvent.prompt();
      const choice = await this.installEvent.userChoice;

      this.dismissPrompt(); // Hide the prompt once the user's clicked

      if (choice.outcome === "accepted") {
        this.$storage.set("appInstalled", true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pwa-prompt-wrapper {
  position: absolute;
  z-index: 1000;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  opacity: 0;
  animation: fadeIn 0.5s forwards;

  .pwa-prompt {
    pointer-events: all;
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 1em;
    background: var(--ion-color-dark-tint);
    color: var(--ion-text-color);
    padding: 0.5em;
    border-radius: 1em;
    opacity: 0;
    transform: translateY(100%);
    animation: fadeInUp 0.5s forwards;
    animation-delay: 0.5s;

    .badge {
      display: inline-block;
      padding: 0.4em 0.5em;
      background: var(--ion-color-primary);
      color: var(--ion-color-dark-tint);

      ion-icon {
        font-size: 1.3em;
      }
    }
    button {
      width: 100%;
      border-radius: 0.5em;
      padding: 0.5em;
      background: var(--ion-color-danger);
      color: var(--ion-text-color);
      font-weight: 600;

      &.primary {
        background: var(--ion-color-primary);
        color: var(--ion-color-dark-tint);
      }
    }

    .prompt-header {
      padding: 0.5em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
    .prompt-body {
      padding: 0.5em;
      font-size: 0.9em;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
