<template>
  <ul
    class="serie-item"
    :class="{
      done,
    }"
  >
    <li class="order">{{ superset ? item.superset_order : item.order }}</li>
    <li>
      <input type="text" v-if="item.max_reps" value="MAX" disabled />
      <input type="number" :value="item.reps" disabled v-else />
      <small>{{ item.unit.short_name }}</small>
    </li>
    <li v-if="item.effort_unit.effort_unit_id !== 1">
      <input
        type="number"
        v-model="form.kgs"
        @focus="onFocus"
        @input="onInput"
        @blur="onBlur"
        step=".01"
      />
      <small>{{ item.effort_unit.short_name }}</small>
    </li>
    <li>
      <IonButton size="block" color="primary" v-if="!done" @click="next">
        <IonIcon :icon="checkmark" />
      </IonButton>
    </li>
  </ul>
  <div class="series-rest" :class="{ done }">
    - DESCANSO {{ item.rest_time }} SEGUNDOS -
  </div>
</template>

<script>
import { IonButton, IonIcon } from "@ionic/vue";
import { checkmark } from "ionicons/icons";

export default {
  components: {
    IonButton,
    IonIcon,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    superset: {
      type: Boolean,
      default: false,
    },
    done: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["next", "update"],
  data: () => ({
    form: {
      kgs: null,
    },
  }),
  setup: () => ({
    checkmark,
  }),
  mounted() {
    this.form.kgs = this.item.kgs;
  },
  methods: {
    next() {
      this.$emit("next");
    },
    onFocus(e) {
      e.target.select();
    },
    onInput(e) {
      this.$emit("update", {
        rutine_exercise_id: this.item.rutine_exercise_id,
        rutine_exercise_serie_id: this.item.rutine_exercise_serie_id,
        reps: this.item.reps,
        kgs: parseFloat(e.target.value).toFixed(2),
      });
    },
    onBlur() {
      this.form.kgs = parseFloat(this.form.kgs).toFixed(2);
    },
  },
};
</script>

<style></style>
