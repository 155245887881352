import { App } from "@capacitor/app";
import moment from "moment";

export default {
  install: (app) => {
    const refresh = () => {
      const { $storage } = app.config.globalProperties;
      $storage.set("refresh_at", moment().add(5, "hours").toISOString());

      setTimeout(() => {
        location.reload(true);
      }, 500);
    };
    const checkLastRefresh = async () => {
      const { $storage } = app.config.globalProperties;
      const refresh_at = await $storage.get("refresh_at");

      if (!refresh_at) return true;

      return moment(refresh_at).isBefore(moment());
    };
    const checkMinVersion = async () => {
      const { $store } = app.config.globalProperties;
      const mv = await $store.dispatch("getMinVersion");

      return mv > $store.getters.app_version;
    };

    const refreshApp = async () => {
      // If Last Time refreshed is more than 5hrs refresh
      if (await checkLastRefresh()) {
        refresh();
        return;
      }

      // If minimum version is lower than actual. refresh
      if (await checkMinVersion()) {
        refresh();
        return;
      }
    };

    App.addListener("appStateChange", async ({ isActive }) => {
      if (isActive) {
        console.log("isActive");
        // app.config.globalProperties.$store.dispatch("getInbox");
        await refreshApp();
      }
    });

    app.config.globalProperties.$appState = {
      refreshApp,
    };
    app.config.globalProperties.$store.$appState = {
      refreshApp,
    };
  },
};
