import { loadingController } from "@ionic/vue";

export default {
  install: async (app) => {
    let loader = null;

    const loaderInstance = {
      async present() {
        if (loader) loader.dismiss();

        loader = await loadingController.create();

        await loader.present();

        setTimeout(() => {
          if (loader) loader.dismiss();
        }, 3000);
      },
      dismiss() {
        if (loader) loader.dismiss();
      },
    };

    app.config.globalProperties.$loader = loaderInstance;
    app.config.globalProperties.$store.$loader = loaderInstance;
  },
};
