<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>

      <div class="responsive-container">
        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="tab1" href="/home/rutine">
            <ion-icon :icon="barbell" />
          </ion-tab-button>

          <ion-tab-button tab="tab2" href="/home/diet">
            <ion-icon :icon="nutrition" />
          </ion-tab-button>

          <ion-tab-button tab="tab3" href="/home/progress">
            <ion-icon :icon="barChart" />
          </ion-tab-button>

          <ion-tab-button
            tab="tab4"
            href="/home/inbox"
            v-if="user?.inbox_active"
          >
            <ion-icon :icon="chatboxEllipses" />
            <div class="with-messages" v-if="hasMessages"></div>
          </ion-tab-button>

          <ion-tab-button tab="tab5" href="/home/profile">
            <ion-icon :icon="person" />
          </ion-tab-button>

          <ion-tab-button tab="tab6" @click="onLogout">
            <ion-icon :icon="power" />
          </ion-tab-button>
        </ion-tab-bar>
      </div>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import {
  barbell,
  nutrition,
  person,
  power,
  chatboxEllipses,
  barChart,
} from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    return {
      barbell,
      nutrition,
      person,
      power,
      chatboxEllipses,
      barChart,
    };
  },
  computed: {
    ...mapGetters(["inbox", "isLoggedIn", "user"]),
    hasMessages() {
      if (!this.inbox?.chat_id) return false;

      return !this.inbox.client_read;
    },
  },
  mounted() {
    this.initApp();
  },
  methods: {
    ...mapActions(["logout"]),

    onLogout() {
      this.logout();
    },
    async initApp() {
      this.$store.dispatch("setLoaded", false);

      await this.$store.dispatch("setCredentials");

      if (!this.$store.getters.isLoggedIn) {
        this.$store.dispatch("setLoaded", true);
        this.$router.replace("/login");
        return;
      }

      await this.$store.dispatch("getUserData");

      // If Progress required. Send to Welcome page
      if (this.$store.getters.progress.required) {
        this.$router.replace("/welcome");
      } else {
        this.$store.dispatch("setLoaded", true);
      }
    },
  },
};
</script>
