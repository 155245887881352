<template>
  <FileUploader @onChange="onPhotoSelected" ref="uploader">
    <div class="ob-photo-uploader">
      <div class="img-container" :style="style">
        <button
          class="close-btn"
          @click.stop="removePhoto"
          v-if="photo_preview"
        >
          <ion-icon :icon="close" />
        </button>
      </div>
      <small>{{ title }}</small>
    </div>
  </FileUploader>
</template>

<script>
import FileUploader from "@/components/FileUploader.vue";
import { IonIcon } from "@ionic/vue";
import { close } from "ionicons/icons";

export default {
  props: {
    mockup_url: {
      type: String,
      default: "/img/man_front.svg",
    },
    title: {
      type: String,
      default: "Frente",
    },
  },
  components: {
    FileUploader,
    IonIcon,
  },
  data: () => ({
    close,
    photo_preview: null,
  }),
  computed: {
    style() {
      return {
        "background-image": `url(${this.photo_preview || this.mockup_url})`,
        "background-size": "contain",
      };
    },
  },
  methods: {
    onPhotoSelected(file) {
      console.log("onPhotoSelected", file);
      this.photo_preview = URL.createObjectURL(file);
    },
    removePhoto() {
      this.$emit("update:modelValue", null);
      this.photo_preview = null;
      this.$refs.uploader.removeFile();
    },
  },
};
</script>
