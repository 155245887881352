<template>
  <ion-page>
    <!-- Fade Header with collapse header -->
    <ion-header mode="ios" collapse="fade" :translucent="true">
      <ion-toolbar>
        <ion-title>Inbox</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" :scroll-y="false">
      <ion-header mode="ios" collapse="condense">
        <div class="responsive-container">
          <ion-toolbar>
            <ion-title size="large">Inbox</ion-title>
          </ion-toolbar>
        </div>
      </ion-header>

      <div class="responsive-container inbox px-3">
        <FaVirtualScroller
          primaryKey="id"
          :getter="getMessages"
          :paginableData="messages"
          reversed
          :key="chat_last_update"
        >
          <template #item="msg">
            <div
              class="msg-item"
              :class="{ 'is-mine': msg.receiver_id === user?.user_id }"
            >
              <div class="msg-header">
                <span class="user">{{ msg.sender.fullname }}</span>
                <ion-button
                  mode="ios"
                  color="transparent"
                  v-if="
                    inbox.last_message_id === msg.chat_message_id &&
                    msg.receiver_id === user.user_id
                  "
                  @click="replying = true"
                >
                  <ion-icon :icon="arrowUndoOutline" />
                </ion-button>
              </div>
              <p>{{ msg.body }}</p>
              <span class="time">{{ dateTimeFormat(msg.created_at) }}</span>
            </div>
          </template>
        </FaVirtualScroller>
      </div>
    </ion-content>

    <ion-footer class="ion-no-border" v-if="replying">
      <ion-toolbar>
        <div class="responsive-container px-3">
          <small class="d-block mt-3 mb-1 text-right">
            {{ 255 - (message?.length || 0) }} caracteres restantes
          </small>
          <ion-item lines="none" class="chatInput">
            <ion-textarea
              inputmode="text"
              class="bg-white text-dark h-100"
              placeholder="Escriba su consulta..."
              v-model="message"
              @ionInput="onInput"
            />
            <ion-button
              mode="ios"
              expand="full"
              class="m-0 h-100 ml-2"
              slot="end"
              @click="sendMsg"
            >
              <ion-icon :icon="send" />
            </ion-button>
          </ion-item>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonFooter,
  IonIcon,
  IonItem,
  IonTextarea,
} from "@ionic/vue";
import { send, arrowUndoOutline } from "ionicons/icons";
import FaVirtualScroller from "@/components/FaVirtualScroller.vue";
import { mapGetters } from "vuex";
import { dateTimeFormat } from "@/composables/time.js";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonFooter,
    IonButton,
    IonIcon,
    IonItem,
    IonTextarea,
    FaVirtualScroller,
  },
  setup: () => ({
    send,
    arrowUndoOutline,
    dateTimeFormat,
  }),
  data: () => ({
    message: null,
    replying: false,
  }),
  computed: {
    ...mapGetters(["inbox", "messages", "chat_last_update", "user"]),
  },
  ionViewDidEnter() {
    if (this.inbox?.chat_id) {
      this.$store.dispatch("updateChat", this.inbox.chat_id);
    }
  },
  methods: {
    getMessages(params) {
      return new Promise((resolve) => {
        if (!this.inbox?.chat_id) {
          this.$store.dispatch("getInbox").then(() => {
            this.$store
              .dispatch("getMessages", { params })
              .then((data) => resolve(data));
          });
        } else {
          this.$store
            .dispatch("getMessages", { params })
            .then((data) => resolve(data));
        }
      });
    },
    sendMsg() {
      this.$store
        .dispatch("sendMessage", {
          message: this.message,
        })
        .then(() => {
          this.message = null;
          this.replying = false;
        });
    },
    onInput(e) {
      const v = e.target.value;

      if (v <= 255) {
        this.message = v;
      } else {
        this.message = v.slice(0, 255);
      }
    },
  },
};
</script>
