import axios from "axios";
import moment from "moment";
const baseUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  state: {
    progress: {},
    progress_fetch_at: null,

    progress_photos: {},
    progress_photos_fetch_at: null,

    selected_goal_id: null,
    chart_loaded: false,
  },
  mutations: {
    SET_PROGRESS(state, value) {
      this.$storage.set("progress", value);
      state.progress = value;
    },
    SET_PROGRESS_FETCH(state, value) {
      state.progress_fetch_at = value;
    },
    SET_PROGRESS_PHOTOS(state, value) {
      this.$storage.set("progress_photos", value);
      state.progress_photos = value;
    },
    SET_PROGRESS_PHOTOS_FETCH(state, value) {
      state.progress_photos_fetch_at = value;
    },

    SET_GOAL_ID(state, value) {
      state.selected_goal_id = value;
    },
    SET_CHART_LOAD(state, value) {
      state.chart_loaded = value;
    },
  },
  actions: {
    getProgress({ getters, commit }, force = false) {
      return new Promise((resolve) => {
        if (force) {
          commit("SET_PROGRESS_FETCH", null);
        }

        if (!getters.is_online || !getters.can_fetch_progress) {
          this.$storage.get("progress").then((data) => {
            commit("SET_PROGRESS", data);

            if (data?.goals.length) {
              commit("SET_GOAL_ID", data.goals[0].user_goal_id);
            }
            resolve();
          });

          return;
        }

        axios
          .get(`${baseUrl}customers/${getters.user.user_id}/progress`, {
            headers: { Authorization: `Bearer ${getters.token}` },
            timeout: 3000,
          })
          .then((res) => {
            commit(
              "SET_PROGRESS_FETCH",
              moment().add("30", "minutes").valueOf()
            );

            commit("SET_PROGRESS", res.data.data);

            if (res.data.data?.goals.length) {
              commit("SET_GOAL_ID", res.data.data.goals[0].user_goal_id);
            }
            resolve();
          })
          .catch(this.$errorHandler);
      });
    },
    getProgressPhotos(
      { getters, commit },
      { params = {}, force = false } = {}
    ) {
      return new Promise((resolve) => {
        if (force) {
          commit("SET_PROGRESS_PHOTOS_FETCH", null);
        }

        if (!getters.is_online || !getters.can_fetch_progress) {
          this.$storage.get("progress_photos").then((data) => {
            commit("SET_PROGRESS_PHOTOS", data);
            resolve();
          });

          return;
        }

        axios
          .get(`${baseUrl}customers/${getters.user.user_id}/progress_photos`, {
            params: {
              page: params.page || 1,
            },
            headers: { Authorization: `Bearer ${getters.token}` },
            timeout: 3000,
          })
          .then((res) => {
            commit(
              "SET_PROGRESS_PHOTOS_FETCH",
              moment().add("30", "minutes").valueOf()
            );

            commit("SET_PROGRESS_PHOTOS", res.data.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
    },
    updateProgress({ getters, dispatch }, form) {
      const fd = new FormData();

      if (form.front_pic) fd.append("front_pic", form.front_pic);
      if (form.back_pic) fd.append("back_pic", form.back_pic);
      if (form.side_pic) fd.append("side_pic", form.side_pic);

      form.metrics.forEach((x) => {
        fd.append(
          "metrics[]",
          JSON.stringify({
            metric_id: x.metric_id,
            current_value: x.current_value,
          })
        );
      });

      fd.append("review", JSON.stringify(form.review));

      this.$loader.present();
      return new Promise((resolve) => {
        axios
          .put(`${baseUrl}customers/${getters.user.user_id}/progress`, fd, {
            headers: { Authorization: `Bearer ${getters.token}` },
            timeout: 3000,
          })
          .then(({ data }) => {
            dispatch("getProgress", true);
            dispatch("getProgressPhotos", { force: true });
            this.$toast.success(data.data);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            this.$loader.dismiss();
          });
      });
    },
    skipProgressForm({ getters, dispatch }) {
      this.$loader.present();
      return new Promise((resolve) => {
        axios
          .post(
            `${baseUrl}customers/${getters.user.user_id}/progress/skip`,
            null,
            {
              headers: { Authorization: `Bearer ${getters.token}` },
              timeout: 3000,
            }
          )
          .then(({ data }) => {
            dispatch("getProgress", true);
            dispatch("getProgressPhotos", { force: true });
            this.$toast.success(data.data);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            this.$loader.dismiss();
          });
      });
    },

    setGoalId({ commit }, goal_id) {
      commit("SET_GOAL_ID", goal_id);
    },
  },
  getters: {
    progress: (state) => state.progress,
    progress_photos: (state) => state.progress_photos,
    can_fetch_progress: (state) => {
      if (!state.progress_fetch_at) return true;

      return moment().isAfter(state.progress_fetch_at);
    },
    goals: (state) => state.progress?.goals || [],
    selected_goal_id: (state) => state.selected_goal_id,
    goal: (state, getters) => {
      if (!getters.goals.length) return null;

      return getters.progress.goals.find(
        (x) => x.user_goal_id === state.selected_goal_id
      );
    },
    goal_current_val: (state, getters) => {
      if (!getters.goal?.progress?.length) return 0;

      return getters.goal.progress[0].value;
    },

    prog_chart_data: (state, getters) => {
      if (!getters.selected_goal_id) {
        return {
          series: [],
          labels: [],
        };
      }

      const invProgress = getters.goal.progress.sort((a, b) =>
        a.date < b.date ? -1 : 1
      );

      return {
        series: [
          {
            name: getters.goal.metric.name,
            data: invProgress.map((x) => x.value),
          },
        ],
        labels: invProgress.map((x) =>
          moment(x.date).locale("es-ES").format("MMM").ucwords()
        ),
      };
    },
  },
};
