<template>
  <ul class="day-selector">
    <li
      v-for="l of list"
      :key="format(l)"
      @click="setDay(l)"
      :class="{
        active: selectedDay.isSame(l),
        disabled: !filledDays.includes(l.day()),
      }"
    >
      <small>{{ days[l.day()] }}</small>
      {{ format(l) }}
    </li>
  </ul>
</template>

<script>
import moment from "moment";

export default {
  props: {
    today: {
      type: Object,
      default: moment(),
    },
    filledDays: {
      type: Array,
      default: () => [1, 3, 5],
    },
    modelValue: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selectedDay: null,
    days: {
      1: "LUN",
      2: "MAR",
      3: "MIE",
      4: "JUE",
      5: "VIE",
      6: "SAB",
      0: "DOM",
    },
    list: [],
  }),
  mounted() {
    this.setDay(this.today.clone());
    this.list = [
      this.today.clone().subtract(3, "days"),
      this.today.clone().subtract(2, "days"),
      this.today.clone().subtract(1, "days"),
      this.today.clone(),
      this.today.clone().add(1, "days"),
      this.today.clone().add(2, "days"),
      this.today.clone().add(3, "days"),
    ];
  },
  methods: {
    format(date) {
      return date.format("DD");
    },
    setDay(day) {
      this.selectedDay = day;
      this.$emit("change", day);
    },
  },
};
</script>

<style></style>
