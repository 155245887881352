import axios from "axios";
import moment from "moment";
const baseUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  state: {
    diet: [],
    diet_fetch_at: null,
    meal: null,
  },
  mutations: {
    SET_DIET(state, value) {
      this.$storage.set("diet", value);
      state.diet = value;
    },
    SET_DIET_FETCH(state, value) {
      state.diet_fetch_at = value;
    },
    SET_MEAL(state, value) {
      state.meal = value;
    },
  },
  actions: {
    getDiet({ commit, getters }) {
      return new Promise((resolve) => {
        if (!getters.is_online || !getters.can_fetch_diet) {
          this.$storage.get("diet").then((data) => {
            commit("SET_DIET", data);
            resolve();
          });

          return;
        }

        axios
          .get(`${baseUrl}customers/${getters.user.user_id}/diets`, {
            headers: { Authorization: `Bearer ${getters.token}` },
            timeout: 3000,
          })
          .then((res) => {
            commit("SET_DIET_FETCH", moment().add("30", "minutes").valueOf());
            commit("SET_DIET", res.data.data);
            resolve(res.data.data);
          })
          .catch(this.$errorHandler);
      });
    },
  },
  getters: {
    diet: (state) => state.diet,
    can_fetch_diet: (state) => {
      if (!state.diet_fetch_at) return true;

      return moment().isAfter(state.diet_fetch_at);
    },
    meal: (state) => state.meal,
  },
};
