<template>
  <button
    class="progress-photo"
    :class="{ disabled: !image }"
    @click="openModal"
  >
    <div
      class="img-container"
      :style="`background-image: url('/img/man_${icon}_filled.svg')`"
    />
    <small>{{ titleFromIcon(icon) }}</small>
  </button>

  <Teleport to="#modal-container">
    <Modal @close="closeModal" size="md" v-if="showModal">
      <template #body>
        <div
          class="img-container contain"
          :style="`background-image: url('${image.url}'); min-height: 85vh`"
        />
      </template>
    </Modal>
  </Teleport>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  props: {
    image: {
      type: Object,
      default: null,
    },
    icon: {
      type: String,
      validator: (val) => ["front", "back", "side"].includes(val),
    },
  },
  components: {
    Modal,
  },
  data: () => ({
    showModal: false,
  }),
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    titleFromIcon(icon) {
      const titles = {
        front: "Frente",
        back: "Espalda",
        side: "Lado",
      };

      return titles[icon];
    },
  },
};
</script>
