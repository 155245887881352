import { Network } from "@capacitor/network";

export default {
  install: (app) => {
    const { $store } = app.config.globalProperties;

    if (Network) {
      const setStatus = (status) => {
        $store.commit("SET_ONLINE_STATUS", status.connected);
      };

      Network.getStatus().then(setStatus);

      Network.addListener("networkStatusChange", setStatus);
    }
  },
};
