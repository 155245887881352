<template>
  <ion-page>
    <!-- Fade Header with collapse header -->
    <ion-header mode="ios" collapse="fade" :translucent="true">
      <ion-toolbar>
        <ion-title>Progreso</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-header mode="ios" collapse="condense">
        <div class="responsive-container">
          <ion-toolbar>
            <ion-title size="large">Progreso</ion-title>
          </ion-toolbar>
        </div>
      </ion-header>

      <div class="responsive-container px-3 pt-2">
        <template v-if="goals?.length">
          <div class="goal-details">
            <div class="goal-details-item">
              <ion-select
                :modelValue="selected_goal_id"
                @ionChange="onSelectGoal($event.target.value)"
                placeholder="Selecciona una Métrica"
              >
                <ion-select-option
                  :value="g.user_goal_id"
                  v-for="g of goals"
                  :key="g.user_goal_id"
                >
                  {{ g.metric?.name }} ({{ g.metric?.unit }})
                </ion-select-option>
              </ion-select>
            </div>

            <div class="d-flex flex-equal gap-2" v-if="goal">
              <div class="goal-details-item">
                <div class="title">OBJETIVO</div>
                <div class="subtitle">
                  {{ goal.value }} {{ goal.metric.unit }}
                </div>
              </div>
              <div class="goal-details-item">
                <div class="title">ACTUAL</div>
                <div class="subtitle">
                  {{ goal_current_val }} {{ goal.metric.unit }}
                </div>
              </div>
            </div>
          </div>

          <AreaChart
            v-if="selected_goal_id"
            :key="selected_goal_id"
            :series="prog_chart_data.series"
            :labels="prog_chart_data.labels"
            :height="285"
          />
        </template>

        <div class="alert alert-warning" v-else>
          Todavía no posee objetivos fijados
        </div>

        <hr />

        <ProgressPhotos />

        <hr />

        <ProgressAttendance />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AreaChart from "@/components/Charts/AreaChart";
import ProgressPhotos from "@/components/Progress/ProgressPhotos";
import ProgressAttendance from "@/components/Progress/ProgressAttendance";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { mapGetters } from "vuex";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSelect,
    IonSelectOption,
    AreaChart,
    ProgressPhotos,
    ProgressAttendance,
  },
  computed: {
    ...mapGetters([
      "user",
      "goals",
      "selected_goal_id",
      "goal",
      "goal_current_val",
      "prog_chart_data",
    ]),
  },
  methods: {
    onSelectGoal(e) {
      this.$store.dispatch("setGoalId", e);
    },
  },
};
</script>
