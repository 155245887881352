<template>
  <ion-page>
    <!-- Fade Header with collapse header -->
    <ion-header mode="ios" collapse="fade" :translucent="true">
      <ion-toolbar>
        <ion-title>Mi Rutina</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header mode="ios" collapse="condense">
        <div class="responsive-container">
          <ion-toolbar>
            <ion-title size="large">Mi Rutina</ion-title>
          </ion-toolbar>
        </div>
      </ion-header>

      <div class="responsive-container px-3">
        <div class="day-selector-wrapper">
          <DaySelector
            v-if="loaded"
            @change="onChangeDay"
            :filledDays="filledDays"
          />
        </div>

        <h4 class="text-center">Sesión del día {{ day?.format("DD/MM") }}</h4>

        <TransitionGroup name="slide-fade" appear>
          <div
            class="alert alert-warning"
            style="--delay: 0.6s"
            v-if="!session"
            key="no-data"
          >
            <p>No hay un plan de entrenamiento programado para hoy.</p>
          </div>
          <ul
            class="icons-details justify-content-center mb-4"
            style="--delay: 0.6s"
            key="title"
            v-else
          >
            <li>
              <ion-icon :icon="stopwatchOutline" />
              <small>{{ session.duration }}</small>
            </li>
            <li>
              <ion-icon :icon="flameOutline" />
              <small>{{ parseInt(session.calories) }}kcal</small>
            </li>
          </ul>
          <ExerciseResume
            v-for="(g, ix) of groups"
            :item="g"
            :key="g.rutine_dow_exgr_id"
            :style="`--delay: ${0.6 + (ix * 2) / 10}s`"
          />
        </TransitionGroup>
      </div>
    </ion-content>

    <ion-footer class="ion-no-border" v-if="groups.length">
      <ion-toolbar>
        <ion-button mode="ios" expand="block" @click="goToRutine">
          Comenzar
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonFooter,
} from "@ionic/vue";
import DaySelector from "@/components/DaySelector";
import ExerciseResume from "@/components/ExerciseResume";
import { mapGetters } from "vuex";
import { stopwatchOutline, flameOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";

export default defineComponent({
  components: {
    DaySelector,
    ExerciseResume,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButton,
    IonFooter,
    IonIcon,
  },
  data: () => ({
    stopwatchOutline,
    flameOutline,
    loaded: false,
    day: null,
  }),
  computed: {
    ...mapGetters(["sessions", "groups", "app_loaded"]),
    filledDays() {
      return this.sessions.map((x) => x.day_of_week);
    },
    session() {
      if (!this.day) return null;

      return this.sessions.find((x) => x.day_of_week === this.day.day());
    },
  },
  ionViewDidEnter() {
    setTimeout(() => {
      this.loaded = true;
    }, 500);
  },
  ionViewWillLeave() {
    this.loaded = false;
  },
  methods: {
    goToRutine() {
      this.$router.push(`/workout`);
    },
    onChangeDay(day) {
      this.day = day;
      this.setExercises(day);
    },
    setExercises(day) {
      this.$store.commit("SET_GROUPS", {
        date: day,
        groups: this.sessions.find((x) => x.day_of_week === day.day())?.groups,
      });
    },
  },
});
</script>
