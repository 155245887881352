<template>
  <ion-page class="onBoarding">
    <ion-header>
      <div class="responsive-container">
        <ion-toolbar>
          <ion-buttons slot="start">
            <img :src="logo" alt="" height="30" class="ml-1" />
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button mode="ios" color="light" @click="dismissForm">
              Omitir
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </div>
    </ion-header>
    <ion-content>
      <Transition mode="out-in" name="slide-fade" appear>
        <div class="ob-wrapper responsive-container">
          <h3 class="title">Chequeo mensual</h3>
          <p class="subtitle">
            Tomate un minuto para completar tus metricas y tener tus progresos
            al día.
          </p>

          <div
            class="ob-metric-container"
            v-for="metric of form.metrics"
            :key="metric.metric_id"
          >
            <label :for="metric.metric_id">
              <b>{{ metric.name }} ({{ metric.unit }})</b>
              <small>{{ metric.comments }}</small>
            </label>
            <input
              :id="metric.metric_id"
              type="number"
              v-model="metric.current_value"
            />
          </div>

          <h3 class="title mt-4">Subí tus fotos</h3>
          <p class="subtitle">
            Para ver la evolucion de tu cuerpo en el tiempo, puedes actualizar
            tus fotos. Te recomendamos que sean en traje de baño.
          </p>

          <div class="ob-photos-container">
            <OBPhotoUploader
              v-model="form.front_pic"
              mockup_url="/img/man_front.svg"
              title="Frente"
            />
            <OBPhotoUploader
              v-model="form.back_pic"
              mockup_url="/img/man_back.svg"
              title="Espalda"
            />
            <OBPhotoUploader
              v-model="form.side_pic"
              mockup_url="/img/man_side.svg"
              title="Lado"
            />
          </div>

          <h3 class="title mt-4">
            Dejá una reseña para <br />
            {{ user?.trainer?.name }}
          </h3>
          <p class="subtitle">
            Comentanos que te parece el servicio que tu entrenador está dándote
          </p>

          <ion-button
            mode="ios"
            expand="block"
            size="small"
            @click="onLeaveReview"
            v-if="!showReviewForm"
          >
            Dejar reseña
          </ion-button>

          <div class="ob-review-container" v-if="showReviewForm">
            <ScoreInput v-model="form.review.score" />

            <textarea
              name=""
              id=""
              rows="5"
              placeholder="Dejanos tus comentarios"
              v-model="form.review.comments"
              @input="onReviewInput"
            ></textarea>
            <small>
              {{ 120 - (form.review.comments?.length || 0) }}/120 caracteres
              restantes
            </small>
          </div>
        </div>
      </Transition>
    </ion-content>

    <ion-footer class="ion-no-border">
      <ion-toolbar>
        <ion-button mode="ios" expand="block" @click="onSave">
          Guardar
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonButton,
  IonFooter,
} from "@ionic/vue";
import { mapGetters } from "vuex";
import OBPhotoUploader from "./OBPhotoUploader.vue";
import ScoreInput from "@/components/ScoreInput.vue";

export default {
  components: {
    IonContent,
    IonPage,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonButton,
    IonFooter,
    OBPhotoUploader,
    ScoreInput,
  },
  data: () => ({
    form: {
      metrics: [],
      front_pic: null,
      back_pic: null,
      side_pic: null,
      review: null,
    },
    showReviewForm: false,
  }),
  computed: {
    ...mapGetters(["user", "progress"]),
    logo() {
      return "/assets/logo-2.svg";
    },
  },
  async ionViewDidEnter() {
    this.$store.dispatch("setLoaded", false);

    await this.$store.dispatch("setCredentials");

    if (!this.$store.getters.isLoggedIn) {
      this.$store.dispatch("setLoaded", true);
      this.$router.replace("/login");
      return;
    }

    await this.$store.dispatch("getUserData").then(() => {
      this.progress.goals.forEach((x) => {
        this.form.metrics.push({
          ...x.metric,
          goal_value: x.value,
          current_value: x.progress.length ? x.progress[0].value : 0,
        });
      });
    });

    this.$store.dispatch("setLoaded", true);
  },
  methods: {
    dismissForm() {
      this.$alert
        .confirm({
          title: "Estás seguro que querés omitir?",
          subtitle:
            "No te preocupes, volveremos a pedirte que completes tus métricas en 24hs.",
        })
        .then(() => {
          this.$store.dispatch("skipProgressForm").then(() => {
            this.$router.push("/home");
          });
        });
    },
    onSave() {
      this.$store
        .dispatch("updateProgress", this.form)
        .then(() => {
          this.$router.push("/home/progress");
        })
        .catch((err) => this.$toast.error(err));
    },
    onReviewInput(e) {
      if (e.target.value.length > 120) {
        this.form.review.comments = e.target.value.slice(0, 120);
      }
    },
    onLeaveReview() {
      this.showReviewForm = true;
      this.form.review = {
        score: 0,
        comments: null,
      };
    },
  },
};
</script>
