<template>
  <div class="media-container">
    <div class="thumbnail" v-if="showThumbnail" @click="playing = true">
      <img :src="thumbnail" :onerror="onImageError" />
      <i class="fa fa-play-circle"></i>
    </div>
    <iframe v-else-if="showVideo" :src="video" frameborder="0"></iframe>
    <img src="/img/video_fallback.jpg" v-else-if="error" />
    <img src="/img/no_video.jpg" v-else />

    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    playing: false,
    error: false,
  }),
  watch: {
    file() {
      this.playing = false;
      this.error = false;
    },
  },
  computed: {
    video() {
      if (!this.file) return null;

      return this.file.url;
    },
    thumbnail() {
      if (!this.file) return null;

      return this.file.thumbnail_url;
    },
    showThumbnail() {
      return !this.error && !this.playing && this.thumbnail;
    },
    showVideo() {
      return this.playing && this.video;
    },
  },
  methods: {
    onImageError() {
      this.error = true;
    },
  },
};
</script>
