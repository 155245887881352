<template>
  <div class="fa-virtual-scroller-wrapper">
    <div class="fa-virtual-scroller-loader" v-if="loading">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div
      class="fa-virtual-scroller"
      :id="compId"
      :class="{ 'fa-virtual-scroller-reversed': reversed }"
      :style="{ gap: `${gap}em` }"
    >
      <div
        class="fa-virtual-scroller-item"
        v-for="item of items"
        :key="item[primaryKey]"
      >
        <slot name="item" v-bind="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    primaryKey: {
      type: String,
      default: null,
      required: true,
    },
    getter: {
      type: Function,
      default: null,
      required: true,
    },
    paginableData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Number,
      default: 150,
    },
    gap: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    loading: true,
    compId: null,
    items: [],
  }),
  mounted() {
    this.compId = Math.floor(Math.random() * Date.now());

    this.$nextTick(() => {
      const fa_vs = document.getElementById(this.compId);
      fa_vs.addEventListener("scroll", this.scrollListener);
    });

    this.getData(1);
  },
  methods: {
    getData(page) {
      this.getter({
        page,
      }).then((data) => {
        if (Array.isArray(data)) {
          this.items = [...this.items, ...data];
        }
        this.$nextTick(() => {
          this.loading = false;
        });
      });
    },
    scrollListener(e) {
      let condition =
        e.target.scrollHeight + e.target.scrollTop - this.offset <=
        e.target.clientHeight;

      if (!this.reversed)
        condition =
          e.target.scrollHeight - e.target.scrollTop - this.offset <=
          e.target.clientHeight;

      if (
        condition &&
        !this.loading &&
        this.paginableData.current_page < this.paginableData.last_page
      ) {
        this.loading = true;

        this.getData(this.paginableData.current_page + 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fa-virtual-scroller-wrapper {
  height: 100%;
  position: relative;
}
.fa-virtual-scroller {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;

  &-reversed {
    flex-direction: column-reverse;
  }
}

/* Absolute Center Spinner */
.fa-virtual-scroller-loader {
  position: absolute;
  z-index: 10003;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: grid;
  place-items: center;

  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
