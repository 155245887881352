<template>
  <ion-page>
    <!-- Fade Header with collapse header -->
    <ion-header mode="ios" collapse="fade" :translucent="true">
      <ion-toolbar>
        <ion-title> {{ meal?.name }} </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header mode="ios" collapse="condense">
        <div class="responsive-container">
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-back-button defaultHref="/home" text="" />
            </ion-buttons>
            <ion-title size="large" class="pl-5">
              {{ meal?.name }}
            </ion-title>
          </ion-toolbar>
        </div>
      </ion-header>

      <div class="responsive-container px-3">
        <TransitionGroup name="slide-fade" v-if="meal?.items.length" appear>
          <DietItem
            v-for="(m, idx) of meal?.items"
            :key="m.food_id"
            :item="m"
            :style="`--delay: ${0.6 + (idx * 2) / 10}s`"
          />
        </TransitionGroup>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import DietItem from "@/components/DietItem";
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
} from "@ionic/vue";
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButtons,
    IonBackButton,
    DietItem,
  },
  computed: {
    ...mapGetters(["diet", "meal"]),
  },
  mounted() {
    if (!this.diet) this.$router.push("/home/diet");
  },
});
</script>
