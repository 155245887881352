<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-y="false">
      <div id="authContainer">
        <div class="responsive-container">
          <img src="/assets/logo.svg" alt="" />

          <div class="auth-card">
            <div class="auth-card-body">
              <h3>Resetar Contraseña</h3>

              <ion-item lines="none">
                <ion-label position="stacked">Nueva Contraseña</ion-label>
                <ion-input
                  name="password"
                  type="password"
                  v-model="form.password"
                />
              </ion-item>

              <ion-item lines="none">
                <ion-label position="stacked">
                  Confirmar Nueva Contraseña
                </ion-label>
                <ion-input
                  name="password_confirmation"
                  type="password"
                  v-model="form.password_confirmation"
                  @keyup.enter="onReset"
                />
              </ion-item>

              <ion-button mode="ios" expand="block" @click="onReset">
                Enviar
              </ion-button>

              <p class="text-center">
                <router-link to="login" class="text-white">
                  <b>Volver al Login</b>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonInput,
  IonButton,
  IonItem,
  IonLabel,
} from "@ionic/vue";

export default {
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonButton,
    IonItem,
    IonLabel,
  },
  data: () => ({
    form: {
      token: null,
      password: null,
      password_confirmation: null,
    },
  }),
  mounted() {
    if (this.$route.query.token) this.form.token = this.$route.query.token;
  },
  methods: {
    onReset() {
      this.$store
        .dispatch("resetPassword", this.form)
        .then((data) => {
          this.$toast.success(data);
          this.$router.push("/login");
        })
        .catch((err) => this.$toast.error(err));
    },
  },
};
</script>
