<template>
  <ion-page>
    <!-- Fade Header with collapse header -->
    <ion-header mode="ios" collapse="fade" :translucent="true">
      <ion-toolbar>
        <ion-title>Mi Dieta</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header mode="ios" collapse="condense">
        <div class="responsive-container">
          <ion-toolbar>
            <ion-title size="large">Mi Dieta</ion-title>
          </ion-toolbar>
        </div>
      </ion-header>

      <div class="responsive-container px-3" v-if="diet?.variants?.length">
        <Pager
          @onChange="changeVariant"
          :pageData="pageData"
          loop
          v-if="diet?.variants.length > 1"
          class="mt-2 mb-3"
        >
          Variante #{{ variant_idx + 1 }}
        </Pager>

        <Transition name="slide-fade" appear>
          <div class="diet-days" :key="variant_idx" style="--delay: 0.6s">
            <div v-for="d of variant.days" :key="variant_idx + '_' + d">
              {{ dowToString(d) }}
            </div>
          </div>
        </Transition>

        <Transition name="slide-fade" appear>
          <div
            class="chart-cont mt-4 pb-4"
            style="--delay: 0.6s"
            :key="variant_idx"
          >
            <div class="chart-wrapper">
              <ChartCard
                chartType="Pie"
                :chartOptions="chartOptions"
                :chartData="chartData"
                v-if="chartData.loaded"
              />
            </div>

            <div class="calories">
              <h4>{{ totals.calories }}</h4>
              <p>Calorías</p>
            </div>

            <div class="chart-labels">
              <div>
                <h4>{{ totals.proteins }}</h4>
                <p>Proteinas</p>
              </div>
              <div>
                <h4>{{ totals.carbs }}</h4>
                <p>Carbohidratos</p>
              </div>
              <div>
                <h4>{{ totals.fat }}</h4>
                <p>Grasas</p>
              </div>
            </div>
          </div>
        </Transition>

        <Transition name="slide-fade" appear v-if="variant.description">
          <div
            class="alert alert-secondary opacity-5"
            :key="variant_idx"
            style="--delay: 0.6s"
          >
            <p>
              {{ variant.description }}
            </p>
          </div>
        </Transition>

        <TransitionGroup name="slide-fade" appear :key="variant_idx">
          <DietItem
            isGroup
            @click="goToGroup(idx)"
            v-for="(meal, idx) of variant.meals"
            :item="meal"
            :key="meal.meal_id + '_' + variant_idx"
            :style="`--delay: ${0.6 + (idx * 2) / 10}s`"
          />
        </TransitionGroup>
      </div>

      <div class="responsive-container px-3" v-else>
        <div class="alert alert-warning">
          Todavía no tiene un plan nutricional asignado.
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { dowToString, formatFloat } from "@/composables/string";
import DietItem from "@/components/DietItem";
import Pager from "@/components/Pager";
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
} from "@ionic/vue";
import { mapGetters } from "vuex";
import ChartCard from "@/components/ChartCard";

export default defineComponent({
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    DietItem,
    ChartCard,
    Pager,
  },
  computed: {
    ...mapGetters(["diet", "isLoggedIn"]),
    variant() {
      if (!this.diet?.variants) return null;

      return this.diet.variants[this.variant_idx];
    },
    totals() {
      if (!this.variant) {
        return {
          calories: 0,
          proteins: 0,
          carbs: 0,
          fat: 0,
        };
      }

      return {
        calories: parseInt(this.variant.calories),
        proteins: this.formatFloat(this.variant.proteins),
        carbs: this.formatFloat(this.variant.carbs),
        fat: this.formatFloat(this.variant.fat),
      };
    },
    pageData() {
      return {
        current_page: this.variant_idx + 1,
        last_page: this.diet?.variants.length || 1,
      };
    },
  },
  data: () => ({
    chartOptions: {
      donut: true,
      showLabel: false,
      donutWidth: 10,
    },
    chartData: {
      labels: [],
      series: [],
      loaded: false,
    },
    variant_idx: 0,
  }),
  ionViewDidEnter() {
    setTimeout(() => {
      this.initChart();
    }, 150);
  },
  methods: {
    dowToString,
    formatFloat,
    goToGroup(idx) {
      this.$store.commit("SET_MEAL", this.variant.meals[idx]);
      this.$router.push("/home/diet/meals");
    },
    initChart() {
      this.chartData.loaded = false;
      this.chartData.series = [];

      this.$nextTick(() => {
        this.chartData.series.push(this.totals.proteins);
        this.chartData.series.push(this.totals.carbs);
        this.chartData.series.push(this.totals.fat);

        this.chartData.loaded = true;
      });
    },
    changeVariant({ page }) {
      this.variant_idx = page - 1;
      this.initChart();
    },
  },
});
</script>
