export function stripHTML(html) {
  return html
    .replace("</p><p>", ". ")
    .replace("&nbsp;", " ")
    .replace(/<[^>]*>?/gm, "");
}

export function maxLength(string, length = 100) {
  if (string.length <= length) return string;

  return string.slice(0, length - 3) + "...";
}

export function parseReps(series) {
  return series.map((s) => (s.max_reps ? "MAX" : s.reps)).join("-");
}

function secondsToString(seconds) {
  let minute = Math.floor((seconds / 60) % 60);
  minute = minute < 10 ? `0${minute}` : minute;
  let second = seconds % 60;
  second = second < 10 ? `0${second}` : second;
  return `${minute}' ${second}''`;
}

export function parseTotalTime(item) {
  return secondsToString(
    item.series.reduce((a, b) => a + b.reps, 0) *
      item.exercise.repetition_duration +
      (item.series.length - 1) * item.rest_time
  );
}

// each 30min 122 ckal => 122 / (30*60) = 0.067 => ckal per second
const calPerSecon = 122 / (30 * 60);

export function parseCalories(item) {
  const time =
    item.series.reduce((a, b) => a + b.reps, 0) *
      item.exercise.repetition_duration +
    (item.series.length - 1) * item.rest_time;

  return Math.floor(calPerSecon * time);
}

export function dowToString(dow) {
  const days = {
    1: "Lunes",
    2: "Martes",
    3: "Miércoles",
    4: "Jueves",
    5: "Viernes",
    6: "Sábado",
    0: "Domingo",
  };
  return days[dow];
}

export function formatFloat(float) {
  return parseFloat(float).toFixed(2);
}

export function moneyFormat(cents, currency = "ARS") {
  return `${currency}  ${(cents / 100).toLocaleString("es-ES", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
}
