<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-header mode="ios" class="ion-no-border">
        <div class="responsive-container">
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-back-button
                defaultHref="/home"
                text=""
                @click="onClickBackButton"
              />
            </ion-buttons>
            <ion-title>
              <span> Rutina dia {{ rutineDate }} </span>
            </ion-title>
          </ion-toolbar>
        </div>
      </ion-header>

      <Transition :name="transitionName" appear>
        <WorkoutExerciseGroup
          :key="group_index"
          v-model="workout_exercises[group_index]"
          :seriesDone="seriesDone"
          @onFinishSerie="finishSerie"
        />
      </Transition>
    </ion-content>
    <ion-footer class="ion-no-border">
      <ion-toolbar>
        <Pager
          @onChange="changeExercise"
          :pageData="pageData"
          loop
          class="mt-1 py-0"
        >
          Ejercicio {{ group_index + 1 }}/{{ workout_exercises.length }}
        </Pager>

        <ion-button mode="ios" expand="block" size="small" @click="endWorkout">
          Guardar
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonButton,
  IonFooter,
} from "@ionic/vue";
import { mapGetters } from "vuex";
import Pager from "@/components/Pager";
import WorkoutExerciseGroup from "@/components/Workout/WorkoutExerciseGroup";
import moment from "moment";

export default defineComponent({
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButtons,
    IonBackButton,
    IonButton,
    IonFooter,
    Pager,
    WorkoutExerciseGroup,
  },
  data: () => ({
    group_index: 0,
    transitionName: "slide-forward",
    workout_exercises: [],
    seriesDone: [],
  }),
  ionViewWillEnter() {
    this.group_index = 0;
    this.workout_exercises = [...this.groups];
    this.seriesDone = [];
  },
  ionViewDidEnter() {
    if (!this.workout_exercises.length) {
      this.$router.push("/home/rutine");
      return;
    }
  },
  computed: {
    ...mapGetters(["groups", "workout_date"]),
    rutineDate() {
      return moment(this.workout_date).format("DD/MM");
    },
    group() {
      return this.workout_exercises[this.group_index];
    },
    pageData() {
      return {
        current_page: this.group_index + 1,
        last_page: this.workout_exercises.length,
      };
    },
  },
  methods: {
    onClickBackButton(e) {
      e.preventDefault();
      this.$alert
        .confirm({
          title: "Saliendo del Modo Entrenamiento",
          subtitle: "Si sale, perderá todos sus cambios, esta seguro?",
        })
        .then(() => {
          this.$router.back();
        });
    },
    changeExercise({ page }) {
      const { current_page, last_page } = this.pageData;
      const fromFirstToLast = current_page === 1 && page === last_page;
      const fromLastToFirst = current_page === last_page && page === 1;

      if (fromFirstToLast || (current_page > page && !fromLastToFirst)) {
        this.transitionName = "slide-backward";
      } else {
        this.transitionName = "slide-forward";
      }

      this.group_index = page - 1;
    },
    finishSerie(serie_id) {
      if (this.seriesDone.includes(serie_id)) return;

      this.seriesDone.push(serie_id);
    },
    parseLog() {
      return Object.values(
        this.workout_exercises
          .flatMap((g) => g.exercises.flatMap((e) => e.series))
          .reduce((a, s) => {
            a[s.rutine_exercise_serie_id] = {
              rutine_exercise_serie_id: s.rutine_exercise_serie_id,
              reps: s.reps,
              kgs: s.kgs,
            };
            return a;
          }, {})
      );
    },
    async endWorkout() {
      await this.$alert.confirm({
        title: "Sesion Terminada!",
        subtitle: "Muy bien! Terminaste con la sesión de hoy",
        html: "Te gustaría guardar tu presentismo y los kgs que hayas modificado?",
      });

      await this.$store.dispatch("saveAttendance", {
        date: moment(this.workout_date).format("YYYY-MM-DD"),
        log: this.parseLog(),
      });

      this.$router.push("/home/rutine");
    },
  },
});
</script>
