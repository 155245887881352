<template>
  <ion-page class="d-flex flex-column">
    <!-- Fade Header with collapse header -->
    <ion-header mode="ios" collapse="fade" :translucent="true">
      <ion-toolbar>
        <ion-title>Mi Perfil</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header mode="ios" collapse="condense">
        <div class="responsive-container">
          <ion-toolbar>
            <ion-title size="large">Mi Perfil</ion-title>
          </ion-toolbar>
        </div>
      </ion-header>

      <div class="responsive-container px-3">
        <div class="mb-4">
          <h3 class="mb-0">Bienvenido {{ user?.name }},</h3>
          <small class="opacity-5 mt-0">
            Desde aquí podrás ver y modificar tus preferencias.
          </small>
        </div>

        <BillingWidget v-if="app_loaded" />

        <ion-item lines="none" v-if="updating_attr === 'email'">
          <ion-label position="stacked">Email</ion-label>
          <ion-input inputmode="text" type="text" v-model="form.email" />
        </ion-item>

        <ion-item lines="none" v-if="updating_attr">
          <ion-label position="stacked">Password</ion-label>
          <ion-input inputmode="text" type="password" v-model="form.password" />
        </ion-item>

        <ion-item lines="none" v-if="updating_attr === 'password'">
          <ion-label position="stacked">Nueva Password</ion-label>
          <ion-input
            inputmode="text"
            type="password"
            v-model="form.new_password"
          />
        </ion-item>

        <ion-item lines="none" v-if="updating_attr === 'password'">
          <ion-label position="stacked">Confirmar Nueva Password</ion-label>
          <ion-input
            inputmode="text"
            type="password"
            v-model="form.new_password_confirmation"
          />
        </ion-item>

        <ion-item
          detail
          lines="none"
          @click="updating_attr = 'email'"
          v-if="updating_attr !== 'email'"
        >
          <small>Cambiar Email</small>
        </ion-item>

        <ion-item
          detail
          lines="none"
          @click="updating_attr = 'password'"
          v-if="updating_attr !== 'password'"
        >
          <small>Cambiar Password</small>
        </ion-item>
      </div>
    </ion-content>
    <ion-footer class="ion-no-border" v-if="updating_attr">
      <ion-toolbar>
        <ion-button mode="ios" expand="block" @click="onUpdate">
          Enviar
        </ion-button>
        <ion-button
          mode="ios"
          color="danger"
          expand="block"
          size="small"
          @click="updating_attr = null"
        >
          Cancelar
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonButton,
  IonInput,
  IonLabel,
  IonFooter,
} from "@ionic/vue";
import BillingWidget from "@/components/BillingWidget.vue";

export default defineComponent({
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonItem,
    IonButton,
    IonInput,
    IonLabel,
    IonFooter,
    BillingWidget,
  },
  data: () => ({
    updating_attr: null,
    form: {
      email: null,
      password: null,
      new_password: null,
      new_password_confirmation: null,
    },
  }),
  computed: {
    ...mapGetters(["user", "app_loaded"]),
  },
  ionViewDidEnter() {
    this.updating_attr = null;

    setTimeout(() => {
      if (this.user) {
        this.form.email = this.user.email;
      }
    }, 100);
  },
  methods: {
    onUpdate() {
      this.$alert
        .confirm({
          title: "Actualizar perfil",
          subtitle: "Está seguro que quiere realizar esta acción?",
        })
        .then(() => {
          this.$store.dispatch("updateProfileData", this.form);
        });
    },
  },
});
</script>
