import axios from "axios";
const baseUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  state: {
    inbox: {},
    inbox_last_update: null,

    messages: {},
    chat_last_update: null,
  },
  mutations: {
    SET_INBOX(state, value) {
      state.inbox = value;
      state.inbox_last_update = Date.now();
    },

    SET_MESSAGES(state, { value, force = false }) {
      state.messages = value;

      if (value && (!state.chat_last_update || force))
        state.chat_last_update = Date.now();
    },
  },
  actions: {
    getInbox({ commit, getters, state }) {
      if (!getters.user.inbox_active) return;

      if (state.inbox_last_update < Date.now() - 30 * 1000) {
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}inbox`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              timeout: 3000,
            })
            .then(({ data }) => {
              commit("SET_INBOX", data.data);

              resolve();
            })
            .catch(this.$errorHandler);
        });
      }
    },

    getMessages({ commit, getters }, { params, force = false }) {
      return new Promise((resolve) => {
        if (!getters.inbox?.chat_id) return;
        if (!force && getters.chat_last_update > Date.now() - 5 * 1000) {
          resolve(getters.messages?.data || []);
          return;
        }

        axios
          .get(`${baseUrl}inbox/${getters.inbox.chat_id}`, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
            timeout: 3000,
            params: {
              ...params,
              per_page: 15,
            },
          })
          .then(({ data }) => {
            commit("SET_MESSAGES", { value: data.data, force });

            if (force) {
              const lastM = data.data.data[0];
              const inbox = { ...getters.inbox };
              inbox.last_message_id = lastM.chat_message_id;

              commit("SET_INBOX", inbox);
            }

            resolve(data.data.data);
          })
          .catch(this.$errorHandler);
      });
    },

    sendMessage({ dispatch, getters }, form) {
      return new Promise((resolve) => {
        axios
          .post(`${baseUrl}inbox`, form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
            timeout: 3000,
          })
          .then(({ data }) => {
            if (!getters.inbox?.chat_id) {
              dispatch("getInbox").then(() => {
                dispatch("getMessages", { force: true });
              });
            } else {
              dispatch("getMessages", { force: true });
            }
            resolve();
            this.$toast.success(data.data);
          })
          .catch(this.$errorHandler);
      });
    },

    updateChat({ getters, commit }, chat_id) {
      return new Promise((resolve) => {
        axios
          .put(
            `${baseUrl}inbox/${chat_id}`,
            { read: true },
            {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              timeout: 3000,
            }
          )
          .then(() => {
            commit("SET_INBOX", {
              ...getters.inbox,
              client_read: true,
            });
            resolve();
          })
          .catch(this.$errorHandler);
      });
    },
  },
  getters: {
    inbox: (state) => state.inbox,

    messages: (state) => state.messages,
    chat_last_update: (state) => state.chat_last_update,
  },
};
