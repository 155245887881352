import axios from "axios";
import moment from "moment";
const baseUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  state: {
    sessions: [],
    sessions_fetch_at: null,
    workout_date: null,
    groups: [],
  },
  mutations: {
    SET_SESSIONS(state, value) {
      this.$storage.set("sessions", value);
      state.sessions = value;
    },
    SET_SESSIONS_FETCH(state, value) {
      state.sessions_fetch_at = value;
    },
    SET_GROUPS(state, { date, groups }) {
      state.workout_date = date || null;
      state.groups = groups || [];
    },
  },
  actions: {
    getSessions({ commit, getters }, force = false) {
      return new Promise((resolve) => {
        if (force) {
          commit("SET_SESSIONS_FETCH", null);
        }

        if (!getters.is_online || !getters.can_fetch_sessions) {
          this.$storage.get("sessions").then((data) => {
            commit("SET_SESSIONS", data);
            resolve();
          });

          return;
        }

        axios
          .get(`${baseUrl}customers/${getters.user.user_id}/sessions`, {
            headers: { Authorization: `Bearer ${getters.token}` },
            timeout: 3000,
          })
          .then((res) => {
            const sessions = res.data.data;

            commit(
              "SET_SESSIONS_FETCH",
              moment().add("30", "minutes").valueOf()
            );
            commit("SET_SESSIONS", sessions);

            if (getters.workout_date) {
              commit("SET_GROUPS", {
                date: getters.workout_date,
                groups: sessions.find(
                  (x) => x.day_of_week === getters.workout_date.day()
                )?.groups,
              });
            }
            resolve();
          })
          .catch(this.$errorHandler);
      });
    },
  },
  getters: {
    sessions: (state) => state.sessions,
    can_fetch_sessions: (state) => {
      if (!state.sessions_fetch_at) return true;

      return moment().isAfter(state.sessions_fetch_at);
    },
    groups: (state) => state.groups,
    workout_date: (state) => state.workout_date,
  },
};
