<template>
  <div class="billing-card">
    <h5 class="m-0">Entrenador</h5>
    <p class="opacity-5 mt-0">
      <small>{{ user?.trainer.name }}</small>
    </p>

    <h5 class="m-0">Vencimiento de subscripción</h5>
    <p class="m-0">
      <small class="opacity-5">
        {{ dateFormat(customer_billing?.expire_at) }}
      </small>

      <small :class="diffToExpire.class" v-if="diffToExpire">
        ({{ diffToExpire.text }})
      </small>
    </p>

    <template v-if="customer_billing?.billing.active">
      <h5 class="mb-0 mt-3">Precio</h5>
      <p class="m-0">
        <small class="opacity-5">
          {{
            moneyFormat(
              customer_billing?.billing.price,
              customer_billing?.billing.currency
            )
          }}
        </small>
      </p>
    </template>

    <ion-button
      mode="ios"
      expand="full"
      size="small"
      class="mt-4"
      @click="onStartCheckout"
      v-if="customer_billing?.billing.active"
    >
      Pagar cuota
    </ion-button>
  </div>
</template>

<script>
import { dateFormat, dateDiff } from "@/composables/time";
import { moneyFormat } from "@/composables/string";
import { mapGetters } from "vuex";
import { IonButton } from "@ionic/vue";

export default {
  components: {
    IonButton,
  },
  computed: {
    ...mapGetters(["customer_billing", "user"]),
    diffToExpire() {
      if (!this.customer_billing?.expire_at) return null;

      const diff = dateDiff(this.customer_billing.expire_at);

      if (!diff || diff > 10) return null;

      if (diff > 5)
        return {
          class: "md-warning",
          text: `Te quedan ${diff} días`,
        };

      if (diff === 1)
        return {
          class: "text-danger",
          text: `Te queda ${diff} día`,
        };

      return {
        class: "text-danger",
        text: `Te quedan ${diff} días`,
      };
    },
  },
  mounted() {
    this.$store.dispatch("getBillingData");
  },
  methods: {
    dateFormat,
    dateDiff,
    moneyFormat,
    onStartCheckout() {
      this.$store.dispatch("startCheckout");
    },
  },
};
</script>

<style></style>
