<template>
  <ion-app>
    <div class="offline-alert" v-if="!is_online">- Sin conexión -</div>
    <ion-router-outlet />

    <div id="modal-container"></div>
  </ion-app>
</template>

<script>
import updateSW from "./mixins/updateSW";
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  mixins: [updateSW],
  computed: {
    ...mapGetters(["is_online"]),
  },
});
</script>
