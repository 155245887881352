<template>
  <div class="exercise-group-resume">
    <div
      class="exercise-resume"
      v-for="e of item.exercises"
      :key="e.rutine_exercise_id"
    >
      <div class="caption">
        <small>{{ e.exercise.muscle.name }}</small>
        <h4>{{ e.exercise.name }}</h4>
        <ul class="icons-details">
          <li>
            <ion-icon :icon="stopwatchOutline" />
            <small>{{ e.duration }}</small>
          </li>
          <li>
            <ion-icon :icon="flameOutline" />
            <small>{{ parseInt(e.calories) }}kcal</small>
          </li>
          <li>
            <ion-icon :icon="repeatOutline" />
            <small>{{ parseReps(e.series) }}</small>
          </li>
        </ul>
      </div>

      <div class="superset">SUPERSERIE</div>
    </div>
  </div>
</template>

<script>
import { stopwatchOutline, flameOutline, repeatOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";
import { parseReps } from "@/composables/string";

export default {
  components: {
    IonIcon,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup: () => ({
    stopwatchOutline,
    flameOutline,
    repeatOutline,
  }),
  methods: {
    parseReps,
  },
};
</script>

<style></style>
