import moment from "moment";

export function dateFormat(val, forUser = true) {
  if (!val) return null;
  return moment(val).format(forUser ? "DD-MM-YYYY" : "YYYY-MM-DD");
}
export function dateDiff(val) {
  if (!val) return 0;
  return moment(val).diff(moment(), "days");
}

export function dateTimeFormat(val, forUser = true, withSeconds = true) {
  if (!val) return null;
  return moment(val).format(
    forUser
      ? `DD-MM-YYYY HH:mm${withSeconds ? ":ss" : ""}`
      : `YYYY-MM-DD HH:mm${withSeconds ? ":ss" : ""}`
  );
}

export function newsDateTimeFormat(val, forUser = true, withSeconds = false) {
  if (!val) return null;
  return moment(val).format(
    forUser
      ? `DD/MM/YY HH:mm${withSeconds ? ":ss" : ""}`
      : `YY/MM/DD HH:mm${withSeconds ? ":ss" : ""}`
  );
}
