import { alertController } from "@ionic/vue";

export default {
  install: async (app) => {
    const alertInstance = {
      async present({ title, subtitle, html, buttons = [], inputs = [] }) {
        const alert = await alertController.create({
          header: title,
          subHeader: subtitle,
          message: html,
          backdropDismiss: true,
          buttons,
          inputs,
        });
        return alert.present();
      },
      async confirm({ title, subtitle, html, inputs = [] }) {
        return new Promise((resolve) => {
          (async () => {
            const alert = await alertController.create({
              header: title,
              subHeader: subtitle,
              message: html,
              backdropDismiss: true,
              buttons: [
                {
                  text: "No",
                  role: "cancel",
                },
                {
                  text: "Si",
                  handler: () => {
                    resolve();
                  },
                },
              ],
              inputs,
            });
            return alert.present();
          })();
        });
      },
    };

    app.config.globalProperties.$alert = alertInstance;
    app.config.globalProperties.$store.$alert = alertInstance; // This one is only needed if you want to access the ionic storage instance in your VUEX store actions
  },
};
