<template>
  <div class="diet-item" :class="{ 'is-group': isGroup }">
    <h4>{{ item.name }}</h4>

    <div class="d-flex align-items-end gap-3 mt-3">
      <p class="m-0" v-if="!isGroup">
        {{ formatFloat(item.grs) }}g
        <span v-if="item.equivalence">
          ({{ parseFloat(item.eq_units) }} {{ item.equivalence.unit_name
          }}{{ item.eq_units > 1 ? "(s)" : null }})
        </span>
      </p>
      <small>{{ parseInt(item.calories) }}kcal</small>
    </div>

    <p class="mt-3 mb-2 opacity-5" v-if="item.description">
      {{ item.description }}
    </p>
    <p
      class="opacity-5"
      v-for="ing of item.ingredients"
      :key="ing.recipe_ingredient_id"
    >
      <small>
        <b>{{ formatFloat(ing.grs) }}g</b> {{ ing.name }}
      </small>
    </p>
    <ul class="icons-details mt-3">
      <li>
        <b>P</b>
        <small>{{ formatFloat(item.proteins) }}</small>
      </li>
      <li>
        <b>C</b>
        <small>{{ formatFloat(item.carbs) }}</small>
      </li>
      <li>
        <b>G</b>
        <small>{{ formatFloat(item.fat) }}</small>
      </li>
    </ul>
  </div>
</template>

<script>
import { flameOutline } from "ionicons/icons";
import { formatFloat } from "@/composables/string";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        name: "Desayuno",
        description: null,
        calories: 120,
        proteins: 100,
        carbs: 150,
        fat: 50,
      }),
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => ({
    flameOutline,
  }),
  methods: {
    formatFloat,
  },
};
</script>

<style></style>
