import axios from "axios";
import $router from "../router";

const baseUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  state: () => ({
    app_loaded: false,
    is_online: false,
    token: null,
    user: null,
  }),
  mutations: {
    SET_TOKEN(state, value) {
      this.$storage.set("token", value);
      state.token = value;
    },
    SET_USER(state, value) {
      this.$storage.set("user", value);
      state.user = value;
    },
    SET_LOADED(state, value) {
      state.app_loaded = value;
    },
    SET_ONLINE_STATUS(state, value) {
      console.log("Online Status: ", value);
      state.is_online = value;
    },
  },
  actions: {
    async setCredentials({ commit }) {
      commit("SET_TOKEN", await this.$storage.get("token"));
      commit("SET_USER", await this.$storage.get("user"));
    },
    async setLoaded({ commit }, value) {
      commit("SET_LOADED", value);

      if (value === true) {
        this.$loader.dismiss();
      } else {
        this.$loader.present();
      }
    },
    login({ commit }, form) {
      this.$loader.present();
      return new Promise((resolve) => {
        axios
          .post(baseUrl + "login", form, {
            timeout: 3000,
          })
          .then(({ data }) => {
            if (form.remember) {
              this.$storage.set("default_email", form.email);
              this.$storage.set("default_pw", form.password);
            } else {
              this.$storage.remove("default_email");
              this.$storage.remove("default_pw");
            }
            commit("SET_TOKEN", data.data.token);
            commit("SET_USER", data.data.user);

            this.$appState.refreshApp();

            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            this.$loader.dismiss();
          });
      });
    },
    loginAsUser({ commit, getters }, user_id) {
      if (!getters.adminToken) return;

      this.$loader.present();
      return new Promise((resolve) => {
        setTimeout(() => {
          axios
            .post(`${baseUrl}login/${user_id}`, null, {
              headers: {
                Authorization: `Bearer ${getters.adminToken}`,
              },
            })
            .then((res) => {
              return res;
            })
            .then(({ data }) => {
              commit("SET_TOKEN", data.data.token);
              commit("SET_USER", data.data.user);

              this.$appState.refreshApp();

              resolve();
            })
            .catch(this.$errorHandler)
            .finally(() => {
              this.$loader.dismiss();
            });
        }, 500);
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);

        commit("SET_DIET", []);
        commit("SET_DIET_FETCH", null);

        commit("SET_SESSIONS", []);
        commit("SET_SESSIONS_FETCH", null);

        commit("SET_PROGRESS", {});
        commit("SET_PROGRESS_FETCH", null);

        commit("SET_ATTENDANCE", null);
        commit("SET_ATTENDANCE_FETCH", null);
        $router.push("/login");
        resolve();
      });
    },

    register(ctx, form) {
      this.$loader.present();
      return new Promise((resolve) => {
        axios
          .post(baseUrl + "register", form, { timeout: 3000 })
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            this.$loader.dismiss();
          });
      });
    },

    forgetPassword(ctx, form) {
      this.$loader.present();
      return new Promise((resolve) => {
        axios
          .post(baseUrl + "forget_password", form, { timeout: 3000 })
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            this.$loader.dismiss();
          });
      });
    },

    resetPassword(ctx, form) {
      this.$loader.present();
      return new Promise((resolve) => {
        axios
          .post(baseUrl + "reset_password", form, { timeout: 3000 })
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(this.$errorHandler)
          .finally(() => {
            this.$loader.dismiss();
          });
      });
    },

    async setDevice({ getters }, { device, device_type, device_model }) {
      return new Promise((resolve) => {
        axios
          .post(
            baseUrl + "device",
            {
              device,
              device_type,
              device_model,
            },
            {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              timeout: 3000,
            }
          )
          .then(() => {
            resolve();
          })
          .catch(this.$errorHandler);
      });
    },

    async updateProfileData({ getters, dispatch }, form) {
      this.$loader.present();

      return new Promise((resolve) => {
        axios
          .put(baseUrl + "profile", form, {
            headers: {
              Authorization: `Bearer ${getters.token}`,
            },
            timeout: 3000,
          })
          .then(({ data }) => {
            dispatch("logout");
            this.$toast.success(data.data);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            this.$loader.dismiss();
          });
      });
    },
    activateUser(_, form) {
      this.$loader.present();

      return new Promise((resolve) => {
        axios
          .post(`${baseUrl}activate_user`, form, { timeout: 3000 })
          .then(({ data }) => {
            this.$toast.success(data.data);
            resolve();
          })
          .catch(this.$errorHandler)
          .finally(() => {
            this.$loader.dismiss();
          });
      });
    },

    getUserData({ dispatch }) {
      return Promise.all([
        dispatch("getSessions"),
        dispatch("getDiet"),
        dispatch("getProgress"),
        dispatch("getProgressPhotos"),
        dispatch("getAttendance"),
        dispatch("getInbox"),
      ]);
    },

    getMinVersion() {
      return new Promise((resolve) => {
        axios
          .get(baseUrl + "health_check")
          .then(({ data }) => {
            resolve(data.mv);
          })
          .catch(this.$errorHandler);
      });
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.token !== null;
    },
    token(state) {
      return state.token;
    },
    user(state) {
      return state.user;
    },
    app_loaded(state) {
      return state.app_loaded;
    },
    is_online(state) {
      return state.is_online;
    },
    adminToken() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("t");
    },
  },
};
