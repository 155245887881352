import axios from "axios";
import moment from "moment";
const baseUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  state: {
    customer_billing: null,
    billing_fetch_at: null,
  },
  mutations: {
    SET_BILLING(state, value) {
      this.$storage.set("customer_billing", value);
      state.customer_billing = value;
    },
    SET_BILLING_FETCH(state, value) {
      state.billing_fetch_at = value;
    },
  },
  actions: {
    getBillingData({ commit, getters }) {
      return new Promise((resolve) => {
        if (!getters.is_online || !getters.can_fetch_billing) {
          this.$storage.get("customer_billing").then((data) => {
            commit("SET_BILLING", data);
            resolve();
          });

          return;
        }

        axios
          .get(`${baseUrl}customers/${getters.user.user_id}/billing`, {
            headers: { Authorization: `Bearer ${getters.token}` },
            timeout: 3000,
          })
          .then((res) => {
            commit(
              "SET_BILLING_FETCH",
              moment().add("30", "minutes").valueOf()
            );
            commit("SET_BILLING", res.data.data);
            resolve(res.data.data);
          })
          .catch(this.$errorHandler);
      });
    },
    startCheckout({ getters }) {
      return new Promise((resolve) => {
        axios
          .post(
            `${baseUrl}customers/${getters.user.user_id}/billing`,
            {},
            {
              headers: { Authorization: `Bearer ${getters.token}` },
              timeout: 3000,
            }
          )
          .then(({ data }) => {
            resolve();

            this.$toast.success(data.data.message);
            setTimeout(() => {
              document.location = data.data.init_point;
            }, 2000);
          })
          .catch(this.$errorHandler);
      });
    },
  },
  getters: {
    customer_billing: (state) => state.customer_billing,
    can_fetch_billing: (state) => {
      if (!state.billing_fetch_at) return true;

      return moment().isAfter(state.billing_fetch_at);
    },
  },
};
