<template>
  <VueApexCharts
    type="area"
    :options="options"
    :series="series"
    :height="height"
  />
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    labels: {
      type: Array,
      default: null,
    },
    series: {
      type: Array,
      default: null,
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  data: () => ({
    chartId: null,
  }),
  computed: {
    options() {
      return {
        chart: {
          id: this.chartId,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#62c4c0"],
        xaxis: {
          categories: this.labels,
          labels: {
            style: {
              colors: "white",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "white",
            },
          },
        },
      };
    },
  },
  methods: {
    /** *
     * Assigns a random id to the chart
     */
    updateChartId() {
      const currentTime = new Date().getTime().toString();
      const randomInt = this.getRandomInt(0, currentTime);
      this.chartId = `div_${randomInt}`;
    },
  },
};
</script>

<style></style>
