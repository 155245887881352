<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-y="false">
      <div id="authContainer">
        <div class="responsive-container">
          <img :src="logo" alt="" />

          <div class="auth-card">
            <div class="auth-card-body">
              <h3>Activación de Usuario</h3>

              <ion-item lines="none">
                <ion-label position="stacked">Password</ion-label>
                <ion-input
                  name="password"
                  type="password"
                  v-model="form.password"
                />
              </ion-item>
              <ion-item lines="none">
                <ion-label position="stacked">Confirmar Password</ion-label>
                <ion-input
                  name="password"
                  type="password"
                  v-model="form.password_confirmation"
                  @keyup.enter="onActivate"
                />
              </ion-item>

              <ion-button mode="ios" expand="block" @click="onActivate">
                Activar Usuario
              </ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
} from "@ionic/vue";

export default {
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonItem,
    IonLabel,
    IonButton,
  },
  data: () => ({
    form: {
      password: null,
      password_confirmation: null,
    },
  }),
  computed: {
    logo() {
      return "/assets/logo.svg";
    },
  },
  methods: {
    onActivate() {
      this.$store
        .dispatch("activateUser", {
          ...this.form,
          token: this.$route.params.token,
        })
        .then(() => {
          this.$router.push("/login");
        })
        .catch((err) => this.$toast.error(err));
    },
  },
};
</script>
