import axios from "axios";
import moment from "moment";
const baseUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  state: {
    attendance: null,
    progress_fetch_at: null,
  },
  mutations: {
    SET_ATTENDANCE(state, value) {
      if (!value || !value.length) {
        this.$storage.set("attendance", []);
        state.attendance = [];
        return;
      }

      const attrs = [
        {
          key: "present",
          highlight: {
            color: "green",
            fillMode: "outline",
          },
          dates: value.map((x) => {
            const d = x.date.split("-");
            return new Date(d[0], d[1] - 1, d[2]);
          }),
        },
        {
          key: "today",
          dot: {
            color: "green",
            fillMode: "light",
          },
          dates: new Date(),
        },
      ];

      this.$storage.set("attendance", value);
      state.attendance = attrs;
    },
    SET_ATTENDANCE_FETCH(state, value) {
      state.attendance_fetch_at = value;
    },
  },
  actions: {
    getAttendance({ commit, getters }, force = false) {
      return new Promise((resolve) => {
        if (force) {
          commit("SET_ATTENDANCE_FETCH", null);
        }

        if (!getters.is_online || !getters.can_fetch_attendance) {
          this.$storage.get("attendance").then((data) => {
            commit("SET_ATTENDANCE", data);
            resolve();
          });

          return;
        }

        axios
          .get(`${baseUrl}customers/${getters.user.user_id}/attendance`, {
            headers: { Authorization: `Bearer ${getters.token}` },
            timeout: 3000,
          })
          .then(({ data }) => {
            commit(
              "SET_ATTENDANCE_FETCH",
              moment().add("30", "minutes").valueOf()
            );

            commit("SET_ATTENDANCE", data.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
    },
    saveAttendance({ getters, dispatch }, form) {
      return new Promise((resolve) => {
        axios
          .post(
            `${baseUrl}customers/${getters.user.user_id}/attendance`,
            form,
            {
              headers: { Authorization: `Bearer ${getters.token}` },
              timeout: 3000,
            }
          )
          .then(({ data }) => {
            this.$toast.success(data.data, 4000);
            dispatch("getSessions", true);
            dispatch("getAttendance", true);
            resolve();
          })
          .catch(this.$errorHandler);
      });
    },
  },
  getters: {
    attendance: (state) => state.attendance,
    can_fetch_attendance: (state) => {
      if (!state.attendance_fetch_at) return true;

      return moment().isAfter(state.attendance_fetch_at);
    },
  },
};
