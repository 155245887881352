import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import storage from "./utils/storage";
import loader from "./utils/loader";
import toast from "./utils/toast";
import alert from "./utils/alert";
import onlineStatus from "./utils/onlineStatus";
import errorHandler from "./utils/errorHandler";
import appState from "./utils/appState";
import VCalendar from "v-calendar";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Theme variables */
import "v-calendar/dist/style.css";
import "./plugins/fontawesome-6.1.1/css/all.min.css";
import "./theme/global.scss";
import "./utils/utilFunctions";
import "./registerServiceWorker";

const app = createApp(App)
  .use(IonicVue, {
    mode: "ios",
  })
  .use(router)
  .use(store)
  .use(storage)
  .use(loader)
  .use(toast)
  .use(alert)
  .use(onlineStatus)
  .use(errorHandler)
  .use(VCalendar, {})
  .use(appState);

router.isReady().then(() => {
  app.mount("#app");
});
