export default {
  install: async (app) => {
    const { $toast, $store } = app.config.globalProperties;

    const errorHandler = (e) => {
      if (e.isAxiosError) {
        if (e.code === "ECONNABORTED") {
          $toast.error(
            "Problemas de conexion, vuelve a intentarlo en unos instantes"
          );
        } else {
          const res = e.response.data;

          // Logout if not authorized
          if ([401, 403].includes(res.code)) {
            $store.dispatch("logout");
          }

          // Show error
          if (res.message && res.message !== "Token has expired")
            $toast.error(res.message);

          // Show errors
          if (res.errors) {
            Object.values(res.errors).forEach((x) => {
              x.forEach((z) => $toast.error(z));
            });
          }
        }

        $store.dispatch("setLoaded", true);
      } else {
        $toast.error("Error inesperado!");
        console.error(e);
      }
    };

    app.config.globalProperties.$errorHandler = errorHandler;
    app.config.globalProperties.$store.$errorHandler = errorHandler;
  },
};
