<template>
  <div class="modal-backdrop" :class="{ closing }">
    <div class="modal">
      <div class="modal-header">
        <slot name="title" />
        <button type="button" class="close" @click="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    closing: false,
  }),
  methods: {
    close() {
      this.closing = true;

      setTimeout(() => {
        this.$emit("close");
      }, 500);
    },
  },
};
</script>
