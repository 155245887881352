<template>
  <div>
    <h4>Asistencia</h4>

    <v-calendar
      is-dark
      is-expanded
      :columns="1"
      :max-date="new Date()"
      timezone="America/Buenos_Aires"
      locale="es-ES"
      :attributes="attendance"
      v-if="attendance?.length"
    />
    <p class="text-center py-2" v-else>
      <small>Aún no realizó ningun entrenamiento</small>
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["attendance"]),
  },
};
</script>
