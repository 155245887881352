import { createStore } from "vuex";
import auth from "./_auth";
import rutine from "./_rutine";
import diet from "./_diet";
import chat from "./_chat";
import progress from "./_progress";
import attendance from "./_attendance";
import billing from "./_billing";

// Create a new store instance.
const store = createStore({
  state: {
    app_version: process.env.VERSION || "0",
  },
  getters: {
    app_version: (state) => {
      return state.app_version;
    },
  },
  modules: {
    auth,
    rutine,
    diet,
    chat,
    progress,
    attendance,
    billing,
  },
});

export default store;
