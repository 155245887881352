import { toastController } from "@ionic/vue";

export default {
  install: async (app) => {
    const toastInstance = {
      async success(msg, duration = 2000) {
        const toast = await toastController.create({
          message: msg,
          color: "primary",
          duration,
          position: "top",
        });
        return toast.present();
      },
      async error(msg) {
        if (msg === "Token has expired")
          return app.config.globalProperties.$store.dispatch("logout");

        const toast = await toastController.create({
          message: msg,
          color: "danger",
          duration: 5000,
          position: "top",
        });
        return toast.present();
      },
    };

    app.config.globalProperties.$toast = toastInstance;
    app.config.globalProperties.$store.$toast = toastInstance; // This one is only needed if you want to access the ionic storage instance in your VUEX store actions
  },
};
