<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-y="false">
      <div id="authContainer">
        <div class="responsive-container">
          <img src="/assets/logo.svg" alt="" />

          <div class="auth-card">
            <div class="auth-card-body">
              <h3>¿Olvidaste tu contraseña?</h3>
              <p>Ingresá tu email para resetearla</p>

              <ion-item lines="none">
                <ion-label position="stacked">Email</ion-label>
                <ion-input
                  name="email"
                  v-model="form.email"
                  @keyup.enter="onForget"
                />
              </ion-item>

              <ion-button mode="ios" expand="block" @click="onForget">
                Resetear contraseña
              </ion-button>

              <p class="text-center">
                <router-link to="login" class="text-white">
                  <b>Volver al Login</b>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonInput,
  IonLabel,
  IonItem,
  IonButton,
} from "@ionic/vue";

export default {
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonLabel,
    IonItem,
    IonButton,
  },
  data: () => ({
    form: {
      email: null,
    },
  }),
  methods: {
    onForget() {
      this.$store.dispatch("forgetPassword", this.form).then((data) => {
        this.$toast.success(data);
      });
    },
  },
};
</script>
