<template>
  <div>
    <h4 class="mb-0">Fotos mensuales</h4>

    <div class="progress-photos-widget-container">
      <div class="progress-photos-main">
        <template v-if="progress_photos?.data?.length">
          <div
            class="progress-photo-item"
            v-for="photo of progress_photos.data"
            :key="photo.customer_progress_photo_id"
          >
            <h5>{{ parseDate(photo.created_at) }}</h5>

            <div class="progress-photos-container">
              <ProgressPhotoPreview icon="front" :image="photo.front_pic" />
              <ProgressPhotoPreview icon="back" :image="photo.back_pic" />
              <ProgressPhotoPreview icon="side" :image="photo.side_pic" />
            </div>
          </div>
        </template>

        <p class="text-center py-3" v-else>
          <small>El cliente aún no subió ninguna foto de progreso</small>
        </p>
      </div>

      <Pager
        @onChange="getPhotos"
        :pageData="progress_photos"
        v-if="progress_photos?.data?.length"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pager from "@/components/Pager.vue";
import moment from "moment";
import ProgressPhotoPreview from "./ProgressPhotoPreview.vue";

export default {
  components: {
    Pager,
    ProgressPhotoPreview,
  },
  computed: {
    ...mapGetters(["progress_photos"]),
  },
  mounted() {
    this.getPhotos();
  },
  methods: {
    getPhotos(params = {}) {
      this.$store.dispatch("getProgressPhotos", {
        params,
      });
    },
    parseDate(date) {
      const dateString = moment(date).locale("ES").format("MMMM YYYY");

      return `${dateString[0].toUpperCase()}${dateString.slice(1)}`;
    },
  },
};
</script>
