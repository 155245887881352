import { createRouter, createWebHistory } from "@ionic/vue-router";
import LoginPage from "@/views/Auth/LoginPage.vue";
import ForgetPasswordPage from "@/views/Auth/ForgetPasswordPage.vue";
import ResetPasswordPage from "@/views/Auth/ResetPasswordPage.vue";
import ActivateUser from "@/views/Auth/ActivateUser.vue";
import HomePage from "@/views/Home/HomePage.vue";
import RutinePage from "@/views/Home/RutinePage.vue";
import DietPage from "@/views/Home/DietPage.vue";
import DietGroupPage from "@/views/Home/DietGroupPage.vue";
import ProfilePage from "@/views/Home/ProfilePage.vue";
import ChatPage from "@/views/Home/ChatPage.vue";
import ProgressPage from "@/views/Home/ProgressPage.vue";
import WorkoutPage from "@/views/Home/WorkoutPage.vue";
import OnBoarding from "@/views/OnBoarding/OnBoarding.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: LoginPage,
  },
  {
    path: "/forget_password",
    component: ForgetPasswordPage,
  },
  {
    path: "/reset_password",
    component: ResetPasswordPage,
  },
  {
    path: "/activate_user/:token",
    component: ActivateUser,
  },
  {
    path: "/home/",
    component: HomePage,
    children: [
      {
        path: "",
        redirect: "/home/rutine",
      },
      {
        path: "rutine",
        component: RutinePage,
      },
      {
        path: "diet",
        component: DietPage,
      },
      {
        path: "diet/meals",
        component: DietGroupPage,
      },
      {
        path: "profile",
        component: ProfilePage,
      },
      {
        path: "inbox",
        component: ChatPage,
      },
      {
        path: "progress",
        component: ProgressPage,
      },
    ],
  },
  {
    path: "/workout",
    component: WorkoutPage,
  },
  {
    path: "/welcome",
    component: OnBoarding,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
