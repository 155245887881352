<template>
  <div class="responsive-container workout-page">
    <WorkoutExerciseVideo :file="file">
      <div class="video-selector" v-if="isSuperset">
        <div
          v-for="(ex, exi) of group?.exercises"
          :key="ex.rutine_exercise_id"
          class="video-selector-option"
          :class="{ selected: this.video_index === exi }"
          @click="this.video_index = exi"
        >
          {{ dictionary[ex.order - 1] }}
        </div>
      </div>
    </WorkoutExerciseVideo>

    <div class="exercise-details">
      <div class="superset" v-if="isSuperset">SUPERSERIE</div>

      <div
        class="exercise-head"
        v-for="ex of group?.exercises"
        :key="ex.rutine_exercise_id"
      >
        <small>{{ ex.exercise.muscle.name }}</small>
        <h4 class="m-0 mb-2">{{ ex.exercise.name }}</h4>
        <div class="superset-indicator" v-if="isSuperset">
          Ejercicio {{ dictionary[ex.order - 1] }}
        </div>

        <ul class="icons-details justify-content-center flex-wrap">
          <li>
            <ion-icon :icon="stopwatchOutline" />
            <small>{{ ex.duration }}</small>
          </li>
          <li>
            <ion-icon :icon="flameOutline" />
            <small>{{ ex.calories }}kcal</small>
          </li>
        </ul>

        <div class="alert alert-secondary mt-3 mb-0" v-if="ex.comments">
          <small>{{ ex.comments }}</small>
        </div>
      </div>

      <SeriesItem
        v-for="s of groupSeries"
        :key="s.rutine_exercise_serie_id"
        :item="s"
        :done="seriesDone.includes(s.rutine_exercise_serie_id)"
        :superset="isSuperset"
        @next="$emit('onFinishSerie', s.rutine_exercise_serie_id)"
        @update="updateSerie($event)"
      />
    </div>
  </div>
</template>

<script>
import {
  stopwatchOutline,
  flameOutline,
  repeatOutline,
  barbellOutline,
} from "ionicons/icons";
import SeriesItem from "@/components/Workout/SeriesItem";
import WorkoutExerciseVideo from "./WorkoutExerciseVideo";
import { IonIcon } from "@ionic/vue";

export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    seriesDone: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    WorkoutExerciseVideo,
    SeriesItem,
    IonIcon,
  },
  setup: () => ({
    stopwatchOutline,
    flameOutline,
    repeatOutline,
    barbellOutline,
  }),
  data: () => ({
    dictionary: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    video_index: 0,
  }),
  computed: {
    group: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    groupSeries() {
      if (!this.group?.exercises?.length) return [];

      const series = {};

      for (const ex of this.group.exercises) {
        for (const s of ex.series) {
          series[`${s.order}${this.dictionary[ex.order - 1]}`] = {
            superset_order: `${s.order}${this.dictionary[ex.order - 1]}`,
            unit: ex.exercise.unit,
            effort_unit: ex.exercise.effort_unit,
            order: s.order,
            kgs: s.kgs,
            reps: s.reps,
            rutine_exercise_id: s.rutine_exercise_id,
            rutine_exercise_serie_id: s.rutine_exercise_serie_id,
            rest_time: s.rest_time,
            max_reps: s.max_reps,
          };
        }
      }

      return Object.values(series).sortBy("superset_order");
    },
    isSuperset() {
      return this.group?.exercises?.length > 1 || false;
    },
    file() {
      return this.group?.exercises[this.video_index]?.exercise?.file;
    },
  },
  methods: {
    updateSerie(log) {
      const exercise = this.group.exercises.find(
        (x) => x.rutine_exercise_id === log.rutine_exercise_id
      );

      const serie = exercise.series.find(
        (x) => x.rutine_exercise_serie_id === log.rutine_exercise_serie_id
      );

      serie.kgs = log.kgs;
    },
  },
};
</script>

<style></style>
